<div class="trees-background">
  <div class="row f1">
    <div class="col-12 col-md-6 d-md-none px-5">
      <img
        src="./assets/images/app/f1-img.png"
        class="devices-img"
        alt="Pictures of eco2wallet app"
      />
    </div>
    <div class="col-12 col-md-6 first-txt-div">
      <p class="first-txt">
        Simplify your business
        <span class="g-color">sustainability</span> efforts with
        <img
          src="./assets/images/logos/Eco2Wallet - intext.png"
          alt=""
          class="logo-text"
        />
        the <br /><span class="g-color size-xl"
          >1<sup>st</sup> green digital wallet</span
        >
      </p>
    </div>
    <div class="col-12 col-md-6 d-none d-md-block px-5">
      <img
        src="./assets/images/app/f1-img.png"
        class="devices-img"
        alt="Pictures of eco2wallet app"
      />
    </div>
  </div>

  <div class="row f2">
    <div class="col-12 col-md-6 px-5">
      <img
        src="./assets/images/app/f2-img.png"
        class="devices-img"
        alt="Pictures of eco2wallet app"
      />
    </div>
    <div class="col-12 col-md-6 second-txt-div">
      <h2 class="list-title"><strong>Receive payments via QR code</strong></h2>
      <ul class="list-container">
        <li>Creating a positive environmental impact.</li>
        <li>
          Analyses the carbon emissions generated by your trasactions and will
          implement carbon negative measures, such as planting trees, on your
          behalf.
        </li>
        <li>Greenefy your sales, transactions and payments.</li>
      </ul>
    </div>
  </div>

  <div class="row f3">
    <div class="col-12 col-md-6 d-md-none img-div">
      <img
        src="./assets/images/app/f3-img.png"
        class="devices-img f3-img"
        alt="Pictures of eco2wallet app"
      />
    </div>
    <div class="col-12 col-md-6 second-txt-div">
      <h2 class="list-title">
        <strong> Understand your sales and their environmental impact </strong>
      </h2>
      <ul class="list-container">
        <li>Creating a positive environmental impact.</li>
        <li>
          Analyses the carbon emissions generated by your trasactions and will
          implement carbon negative measures, such as planting trees, on your
          behalf.
        </li>
        <li>Greenefy your sales, transactions and payments.</li>
      </ul>
    </div>
    <div class="col-12 col-md-6 d-none d-md-block img-div">
      <img
        src="./assets/images/app/f3-img.png"
        class="devices-img f3-img"
        alt="Pictures of eco2wallet app"
      />
    </div>
  </div>

  <div class="row f4">
    <div class="col-12 col-md-6 img-div">
      <img
        src="./assets/images/app/f4-img.png"
        alt="Eco2Wallet cards"
        class="devices-img"
      />
    </div>
    <div class="col-12 col-md-6 second-txt-div">
      <h2 class="list-title">
        <strong> Make your business debit/credit cards sustainable </strong>
      </h2>
      <ul class="list-container">
        <li>
          Are you a card issuer? If so, partner with eco2wallet to make your
          transactions sustainable and your cards
          <span class="g-color">eco<sub>2</sub>card</span>
        </li>
      </ul>
    </div>
  </div>

  <div class="row f5">
    <div class="col-12 col-md-6 d-md-none px-5">
      <img
        src="./assets/images/style/woman-circle.png"
        class="f5-img my-5"
        alt="Pictures of eco2wallet app"
      />
    </div>
    <div class="col-12 col-md-6 my-5 my-md-0">
      <img
        src="./assets/images/logos/Eco2Wallet - intext.png"
        class="f5-logo"
        alt="Logo of Eco2wallet"
      />
      <p class="f5-txt">
        Whether you are small, medium, or large business,
        <strong class="g-color bold">think green.</strong>
        <br />
        Start your journey to make your business go from carbon neutral to
        <strong class="g-color bold">carbon free.</strong>
      </p>
    </div>
    <div class="col-12 col-md-6 d-none d-md-block px-5">
      <img
        src="./assets/images/style/woman-circle.png"
        class="f5-img"
        alt="Pictures of eco2wallet app"
      />
    </div>
  </div>

  <div class="row f6">
    <div class="col-12">
      <p>
        <strong class="g-color bold">
          Have a better insight of scope 2 and scope 3 emissions: Report better,
          avoid overpaying environmental charges, increase CO<sub>2</sub>
          visibility and, showcase your tree certificates.
        </strong>
      </p>
    </div>
  </div>

  <div class="row f7 mx-md-5 d-flex align-items-center">
    <div class="col-12 col-md-6">
      <div class="card-container card-face">
        <div class="card card-face">
          <div class="front card-face">
            <img
              src="./assets/images/app/certificado1.jpg"
              alt="Eco2wallet certificate image"
              class="certificate-img"
            />
          </div>
          <div class="back card-face">
            <img
              src="./assets/images/app/certificado-02.jpg"
              alt="Eco2wallet certificate image"
              class="certificate-img"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 sixth-txt-div">
      <p class="sixth-txt">
        Money doesn't grow on trees, but your money in
        <img
          src="./assets/images/logos/Eco2Wallet - intext.png"
          alt=""
          class="logo-text no-pad"
        />
        will make trees grow
      </p>
      <p class="w-txt">
        <strong>
          For each transaction we plant trees in the Amazon and your business
          receive forestry certificates.
        </strong>
      </p>
    </div>
  </div>

  <div class="row f8">
    <div class="col-12 col-md-6 my-5 d-md-none">
      <img
        src="./assets/images/style/siembra.png"
        alt="Hands planting image"
        class="plant-img"
      />
    </div>
    <div class="col-12 col-md-6 seventh-txt-div">
      <p class="seventh-txt">
        Simplify your <span class="g-color">sustainability</span> efforts when
        you partner with
        <img
          src="./assets/images/logos/Eco2Wallet - intext.png"
          alt=""
          class="logo-text"
        />
      </p>
    </div>
    <div class="col-12 col-md-6 d-none d-md-block">
      <img
        src="./assets/images/style/siembra.png"
        alt="Hands planting image"
        class="plant-img"
      />
    </div>
  </div>

  <div
    class="row text-center join-div hide-on-init pb-5 my-5"
    animateOnScroll
    animationName="animated fadeInUp"
  >
    <img src="./assets/images/style/dots.svg" alt="" class="join-dots r180" />
    <a
      (click)="openModal()"
      class="join-link"
      data-toggle="modal"
      data-target="#form-modal"
    >
      Join the waiting list here
    </a>
    <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
    <span class="mail-txt"
      >Or
      <a href="mailto:contactus@eco2wallet.com" class="mail-link"
        >contactus@eco2wallet.com</a
      ></span
    >
  </div>

  <div class="row">
    <div class="col-12 desc-txt-div">
      <p class="desc-txt">
        <span class="g-color">eco<sub>2</sub>wallet</span>,
        <span class="g-color">eco<sub>2</sub>card</span> and
        <span class="g-color">eco<sub>2</sub>app</span> are designed with
        sustainability at all points, CO<sub>2</sub>
        stands for Carbon Dioxide emissions which are the primary pollutant
        causing climate change, our background is primarily black, saving you
        ~0,103 watts hours than any other website, our trees in the background
        are native amazonian and our fonts is thin in case you have to print
        information, we provide true balance between people, profit and planet!
      </p>
    </div>
    <div class="footer row">
      <div class="col-md-4 col-12 awards">
        <a href="#" title="Find us on EIT">
          <img
            src="../assets/images/logos/eit.png"
            alt="EIT digital"
            class="award-img"
          />
        </a>
        <a href="#" class="">
          <img
            src="../assets/images/logos/EU commission.png"
            alt="European Commision"
            class="award-img"
          />
        </a>
        <a
          href="https://www.seis.co.uk/united-kingdom/london/fintech/eco2wallet?from=badge"
          title="Find us on SEIS.co.uk"
          target="_blank"
          class=""
        >
          <img
            src="https://www.seis.co.uk/images/memberbadge.png"
            border="0"
            alt="Member of SEIS"
            class="award-img"
          />
        </a>
        <a
          href="https://websummit.com/"
          title="We are in WebSummit"
          target="_blank"
          class=""
        >
          <img
            src="../assets/images/logos/websummit.png"
            border="0"
            alt="WebSummit impact startup"
            class="award-img"
          />
        </a>
      </div>
      <div class="col-md-6 col-12 my-5 my-md-0 social-networks">
        <a href="tel:+447865493029" class="social-link">
          <fa-icon [icon]="telephone" class="social-icon"></fa-icon>
        </a>
        <a
          href="https://www.linkedin.com/company/eco2wallet/"
          class="social-link"
        >
          <fa-icon [icon]="linkedin" class="social-icon"></fa-icon>
        </a>
        <a
          href="https://www.facebook.com/Eco2wallet-green-digital-wallet-108859671462372"
          class="social-link"
        >
          <fa-icon [icon]="facebook" class="social-icon"></fa-icon>
        </a>
        <a href="https://www.instagram.com/eco2wallet/" class="social-link">
          <fa-icon [icon]="instagram" class="social-icon"></fa-icon>
        </a>
        <img
          src="./assets/images/style/dots.svg"
          alt=""
          class="network-dots r90 black-and-white"
        />
        <p class="copyright-txt col-12 col-md-auto d-md-inline-block">
          U.K. Company # 11174218, eco2wallet, eco2card, eco2app, and associated
          brands are registered under UK law. 2021.
        </p>
      </div>
    </div>
  </div>
</div>
