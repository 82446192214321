<span
  style="font-size: 30px; cursor: pointer"
  class="g-color"
  (click)="openNav()"
  >&#9776;</span
>
<div id="sideNav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <a
    [routerLink]="['/personal']"
    [routerLinkActive]="['is-active']"
    class="option"
    >Personal</a
  >
  <a
    [routerLink]="['/business']"
    [routerLinkActive]="['is-active']"
    class="option"
    >Business</a
  >
  <a
    [routerLink]="['/about-us']"
    [routerLinkActive]="['is-active']"
    class="option"
    >About Us</a
  >
  <a (click)="openModal()">Join Us</a>
</div>
