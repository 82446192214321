import * as THREE from 'three';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
  styleUrls: ['./dots.component.scss'],
})
export class DotsComponent implements OnInit {
  private threshold = 0.1;
  private pointSize = 0.08;
  private width = 80;
  private length = 160;
  private rotateY = new THREE.Matrix4().makeRotationY(0.005);

  private canvas: HTMLCanvasElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;

  private pcIndexed: THREE.Points;

  private geometries = [];

  private asc = true;

  private co = 0.01;

  private frameId: number = null;
  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  constructor(private ngZone: NgZone) {}

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true, // transparent background
      antialias: true, // smooth edges
    });
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    // create the scene
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      25,
      this.canvas.clientWidth / (this.canvas.clientHeight * 2),
      1,
      10000
    );
    this.camera.position.z = 5;
    this.scene.add(this.camera);
    this.pcIndexed = this.generateIndexedPointcloud(
      new THREE.Color(0, 1, 0),
      this.width * 2,
      this.length
    );
    this.pcIndexed.scale.set(10, 10, 10);
    this.pcIndexed.position.set(0, 0, 0);
    this.scene.add(this.pcIndexed);

    // const pcIndexedOffset = generateIndexedWithOffsetPointcloud( new THREE.Color( 0, 1, 0 ), width, length );
    // pcIndexedOffset.scale.set( 5, 10, 10 );
    // pcIndexedOffset.position.set( 2.5, 0, 0 );
    // scene.add( pcIndexedOffset );

    this.camera.position.set(7, -1, -7);
    // camera.rotation.set(1.1, 1.9, 2.3);
    this.camera.lookAt(0, -1.8, 0);
    this.camera.projectionMatrix.scale(new THREE.Vector3(-0.8, 1, 1));
    this.camera.updateMatrix();

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  }

  public ngOnInit(): void {
    this.createScene(this.rendererCanvas);
    this.animate();
    window.addEventListener('resize', this.onWindowResize, false);
  }

  public generatePointCloudGeometry(color, width, length, coeficint) {
    const geometry = new THREE.BufferGeometry();
    const numPoints = width * length;

    const positions = new Float32Array(numPoints * 3);
    const colors = new Float32Array(numPoints * 3);

    let k = 0;

    for (let i = 0; i < width; i++) {
      for (let j = 0; j < length; j++) {
        const u = i / width;
        const v = j / length;
        const x = u - 0.5;
        let y = (Math.cos(u * Math.PI * 4) + Math.sin(v * Math.PI * 8)) / 20;
        if (coeficint) {
          y *= coeficint;
        }
        const z = v - 0.5;

        positions[3 * k] = x;
        positions[3 * k + 1] = y;
        positions[3 * k + 2] = z;

        let intensity = (y + 0.1) * 5;
        // intensity = 0.4;
        colors[3 * k] = color.r * intensity;
        colors[3 * k + 1] = color.g * intensity;
        colors[3 * k + 2] = color.b * intensity;

        k++;
      }
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.computeBoundingBox();

    return geometry;
  }

  public generatePointcloud(color, width, length) {
    const geometry = this.generatePointCloudGeometry(
      color,
      width,
      length,
      undefined
    );
    const material = new THREE.PointsMaterial({
      size: this.pointSize,
      vertexColors: true,
    });

    return new THREE.Points(geometry, material);
  }

  public generateIndexedWithOffsetPointcloud(color, width, length) {
    const geometry = this.generatePointCloudGeometry(
      color,
      width,
      length,
      undefined
    );
    const numPoints = width * length;
    const indices = new Uint16Array(numPoints);

    let k = 0;

    for (let i = 0; i < width; i++) {
      for (let j = 0; j < length; j++) {
        indices[k] = k;
        k++;
      }
    }

    geometry.setIndex(new THREE.BufferAttribute(indices, 1));
    geometry.addGroup(0, indices.length);

    const material = new THREE.PointsMaterial({
      size: this.pointSize,
      vertexColors: true,
    });

    return new THREE.Points(geometry, material);
  }

  public generateIndexedPointcloud(color, width, length) {
    const geometry = this.generatePointCloudGeometry(
      color,
      width,
      length,
      undefined
    );
    const numPoints = width * length;
    const indices = new Uint16Array(numPoints);

    let k = 0;

    for (let i = 0; i < width; i++) {
      for (let j = 0; j < length; j++) {
        indices[k] = k;
        k++;
      }
    }

    geometry.setIndex(new THREE.BufferAttribute(indices, 1));

    const material = new THREE.PointsMaterial({
      size: this.pointSize,
      vertexColors: true,
    });

    return new THREE.Points(geometry, material);
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      window.addEventListener('resize', () => {
        this.resize();
      });
    });
  }

  public render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });
    let color = new THREE.Color(0, 1, 0);
    if (this.co > 0.3) {
      this.asc = false;
    } else if (this.co < -0.3) {
      this.asc = true;
    }

    let velo = 0.0005;

    if (this.asc) {
      this.co += velo;
    } else {
      this.co -= velo;
    }
    let geo = this.generatePointCloudGeometry(
      color,
      this.width * 1,
      this.length,
      this.co
    );
    this.geometries.forEach((geom) => {
      geom.dispose();
    });
    this.geometries = [];
    this.pcIndexed.geometry = geo;
    this.geometries.push(geo);
    this.renderer.render(this.scene, this.camera);
  }

  public resize(): void {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  private onWindowResize() {
    // let canvas = this.rendererCanvas.nativeElement;
    // this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
    // this.camera.updateProjectionMatrix();
    // this.renderer.setSize(canvas.clientWidth, canvas.clientHeight);
  }
}
