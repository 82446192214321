<!-- Form modal -->
<div
  class="modal fade"
  id="form-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="FormModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-top">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="body-modal">
        <img
          src="./assets/images/logos/imagotipo eco2wallet-03.png"
          alt="eco2wallet logo"
          class="modal-img"
        />
        <p>
          Currently, <span>eco2wallet</span> is under development. If you want
          to get notified of new updates fill this form to join the waiting
          list.
        </p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="legacy" color="primary">
            <mat-label>Full name</mat-label>
            <input
              matInput
              placeholder="Enter your name"
              [formControl]="name"
            />
            <mat-icon matSuffix>person</mat-icon>
            <mat-hint>David Jones</mat-hint>
            <mat-error *ngIf="name.invalid && name.touched"
              >Please enter your name</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="legacy" color="primary">
            <mat-label>Email address</mat-label>
            <input
              matInput
              placeholder="Enter your email"
              [formControl]="email"
            />
            <mat-icon matSuffix>alternate_email</mat-icon>
            <mat-hint>example@gmail.com</mat-hint>
            <mat-error *ngIf="email.invalid && email.touched"
              >Please enter a valid email</mat-error
            >
          </mat-form-field>
          <button type="submit" class="submit-button" [disabled]="isLoading">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Content -->

<div class="dot-mesh">
  <app-dots></app-dots>
</div>
<div class="limit-width">
  <div class="container-fluid">
    <div class="row d-flex d-md-none header-div justify-space-between">
      <div class="col-1 d-block d-md-none z-front">
        <app-side-menu
          class="side-menu-component d-block d-md-none"
        ></app-side-menu>
      </div>
      <div class="col-11 col-md-7 z-front-2">
        <img
          src="./assets/images/logos/imagotipo eco2wallet-03.png"
          alt="eco2wallet logo"
          class="header-img"
        />
      </div>
    </div>
    <div animateOnScroll animationName="animated fadeInDown" id="header">
      <div class="row header-div d-none d-md-flex">
        <div class="col-1 d-block d-md-none">
          <app-side-menu
            class="side-menu-component d-block d-md-none"
          ></app-side-menu>
        </div>
        <div class="col-11 col-md-7 z-front">
          <img
            src="./assets/images/logos/imagotipo eco2wallet-03.png"
            alt="eco2wallet logo"
            class="header-img"
          />
        </div>

        <div
          class="
            col-12 col-md-5
            my-5 my-md-0
            d-md-block d-none
            header-options-div
          "
        >
          <a
            [routerLink]="['/personal']"
            [routerLinkActive]="['is-active']"
            class="option"
          >
            <span>Personal</span>
            <img src="./assets/images/style/dots.svg" alt="" class="op-dots" />
          </a>

          <a
            [routerLink]="['/business']"
            [routerLinkActive]="['is-active']"
            class="option"
          >
            <span>Business</span>
            <img src="./assets/images/style/dots.svg" alt="" class="op-dots" />
          </a>

          <a
            [routerLink]="['/about-us']"
            [routerLinkActive]="['is-active']"
            class="option"
          >
            <span>About us</span>
            <img src="./assets/images/style/dots.svg" alt="" class="op-dots" />
          </a>
        </div>
      </div>
    </div>
    <div class="spacer">
      <div class="opaque d-md-block d-none"></div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
