import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

declare var $: any;

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  ngOnInit(): void {
  }

  telephone = faPhoneAlt;
  linkedin = faLinkedin;
  facebook = faFacebookF;
  instagram = faInstagram;

  form: FormGroup;
  name: FormControl = new FormControl('', [Validators.required]);
  email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  submitted = false; // show and hide the success message
  isLoading = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.form = this.formBuilder.group({
      name: this.name,
      email: this.email
    });
  }

  onSubmit(): void {
    if (this.form.status === 'VALID') {
      this.form.disable(); // disable the form if it's valid to disable multiple submissions
      var formData: any = new FormData();
      formData.append("name", this.form.get("name").value);
      formData.append("email", this.form.get("email").value);
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits
      this.http.post("https://script.google.com/macros/s/AKfycbyHutZm9R8-dG1TdVyOoJs4XjBmtcq62JCAgiQ74yI7qWjh6A5SDEivSisgAByRtcNdrw/exec", formData).subscribe(
        (response) => {
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(response);
        },
        (error) => {
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(error);
        }
      );
      this.form.reset();
      this.closeModal();
    }
  }

  openModal(): void {
    $('#form-modal').modal('show');
  }
  closeModal(): void {
    $('#form-modal').modal('hide');
  }

}
