<main>
  <section>
    <div class="trees-background">
      <div
        class="row text-center join-div hide-on-init"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <img
          src="./assets/images/style/dots.svg"
          alt=""
          class="join-dots r180"
        />
        <a
          (click)="openModal()"
          class="join-link"
          data-toggle="modal"
          data-target="#form-modal"
        >
          Join the waiting list here
        </a>
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
      </div>
      <div class="row mb-md-5 pb-md-5">
        <div
          class="col-md-7 col-12 first-img-div hide-on-init"
          animateOnScroll
          animationName="animated fadeInLeft"
        >
          <img
            src="./assets/images/app/first-image.png"
            class="phone-img"
            alt="Pictures of eco2wallet app"
          />
        </div>
        <div
          class="
            col-md-5 col-12
            first-txt-div
            hide-on-init
            my-md-0
            py-md-0
            my-5
            py-5
          "
          animateOnScroll
          animationName="animated fadeInRight"
        >
          <p class="first-txt">
            Your <span class="gy-color">wallet</span>, but
            <span class="g-color size-xxl">sustainable</span>
            and
            <span class="gy-color size-l">digital</span>
          </p>
        </div>
      </div>
      <div
        class="row intro-div hide-on-init pt-5 mt-5 pb-5 mb-5 pb-md-0 mb-md-0"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <div class="col-12">
          <p class="intro-txt">
            Imagine a digital Personal Identification, all your debit and credit
            cards in one, awareness and trading of your individual emissions,
            plant trees in the Amazon with each purchase while owning
            sustainable crypto. All in your eco2wallet.
          </p>
        </div>
      </div>
      <div
        class="row text-center join-div hide-on-init pb-5 mb-5"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <img
          src="./assets/images/style/dots.svg"
          alt=""
          class="join-dots r180"
        />
        <a
          href="mailto:techsupport@eco2wallet.com?subject=I%20want%20to%20be%20part%20of%20eco2wallet&body=Hi%20Tech%20Team!%0D%0A%0D%0AI%20am%20very%20excited%20about%20becoming%20a%20beta%20tester%20of%20Eco2Wallet.%20How%20can%20I%20become%20one%3F"
          class="join-link"
        >
          Click here to become a beta tester
        </a>
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
        <span class="mail-txt"
          >Or
          <a href="mailto:contactus@eco2wallet.com" class="mail-link"
            >contactus@eco2wallet.com</a
          ></span
        >
      </div>
      <div class="row partners-div py-5">
        <h1
          class="size-m g-color round-font d-block hide-on-init"
          animateOnScroll
          animationName="animated fadeInUp"
        >
          Proud Partners
          <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
        </h1>
        <div class="logos-row mt-5">
          <img
            src="./assets/images/logos/eit-digital.png"
            class="logos-img bigger-logo hide-on-init animated-delay-05"
            alt="EIT Digital"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/logos/finnovation-white.png"
            class="logos-img hide-on-init animated-delay-05"
            alt="Finance Innovation logo"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/logos/bamboo.png"
            class="logos-img hide-on-init animated-delay-05 d-none"
            alt="Bamboo Orchard Logo"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/logos/tenity.png"
            class="logos-img hide-on-init animated-delay-05 d-none"
            alt="Tenity"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/logos/Intergiro.png"
            class="logos-img hide-on-init animated-delay-05 d-none"
            alt="Intergiro Logo"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/logos/innsomnia.png"
            class="logos-img hide-on-init animated-delay-05"
            alt="Insomnia Logo"
            animateOnScroll
            animationName="animated fadeInUp"
          />
        </div>
      </div>
      <div class="row desc-div py-5">
        <div class="sombra-cel d-none d-md-block"></div>
        <div
          class="col-md-5 d-md-none hide-on-init"
          animateOnScroll
          animationName="animated fadeInUp"
        >
          <img
            src="./assets/images/app/second-image.png"
            class="phone-img-2"
            alt="Pictures of eco2wallet app"
          />
        </div>
        <div
          class="col-md-7 col-12 hide-on-init"
          animateOnScroll
          animationName="animated fadeInLeft"
        >
          <div class="second-txt-div">
            <p class="second-txt">
              A
              <span class="g-color size-xl">rainforest</span>
              in your digital
              <span class="go-color size-l">wallet</span>
            </p>
          </div>
        </div>
        <div
          class="col-md-5 d-none d-md-block hide-on-init"
          animateOnScroll
          animationName="animated fadeInRight"
        >
          <img
            src="./assets/images/app/second-image.png"
            class="phone-img-2"
            alt="Pictures of eco2wallet app"
          />
        </div>
      </div>
      <div
        class="row intro-div hide-on-init pt-5 mt-5"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <div class="col-12">
          <p class="desc-txt">
            <span class="g-color">eco<sub>2</sub>wallet</span>,
            <span class="g-color">eco<sub>2</sub>card</span> and
            <span class="g-color">eco<sub>2</sub>app</span> are designed with
            sustainability at all points, CO<sub>2</sub>
            stands for Carbon Dioxide emissions which are the primary pollutant
            causing climate change, our background is primarily black, saving
            you ~0,103 watts hours than any other website, our trees in the
            background are native amazonian and our fonts is thin in case you
            have to print information, we provide true balance between people,
            profit and planet!
          </p>
        </div>
      </div>
      <div class="row features-div py-5">
        <h1
          class="size-m g-color round-font d-block hide-on-init"
          animateOnScroll
          animationName="animated fadeInUp"
        >
          Key Features
          <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
        </h1>
        <div class="img-row mt-5">
          <img
            src="./assets/images/app/Key features_Mesa de trabajo 1.png"
            class="features-img hide-on-init animated-delay-05"
            alt="Digital ID"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/app/Key features-02.png"
            class="features-img hide-on-init animated-delay-1"
            alt="All your debit and gredit cards are in one"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/app/Key features-03.png"
            class="features-img hide-on-init animated-delay-15"
            alt="Sustainable open banking"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/app/Key features-04.png"
            class="features-img hide-on-init animated-delay-2"
            alt="Trade your emissions with everyone"
            animateOnScroll
            animationName="animated fadeInUp"
          />
          <img
            src="./assets/images/app/Key features-05.png"
            class="features-img hide-on-init animated-delay-25"
            alt="Own sustainable crypto"
            animateOnScroll
            animationName="animated fadeInUp"
          />
        </div>
      </div>
      <div class="row cards-div">
        <div class="col-12 text-center my-5">
          <h1
            class="size-m g-color mb-0 hide-on-init"
            animateOnScroll
            animationName="animated fadeInUp"
          >
            <img
              src="./assets/images/style/dots.svg"
              alt=""
              class="join-dots r180"
            />
            Your wallet in one
            <img
              src="./assets/images/style/dots.svg"
              alt=""
              class="join-dots"
            />
          </h1>
        </div>
        <div
          class="
            col-12 col-md-6
            text-center
            mt-4
            hide-on-init
            animated-delay-05
          "
          animateOnScroll
          animationName="animated fadeInLeft"
        >
          <img
            class="card-img"
            src="./assets/images/app/new_card1.webp"
            alt="Eco2card front"
          />
        </div>
        <div
          class="
            col-12 col-md-6
            text-center
            mt-4
            hide-on-init
            animated-delay-05
          "
          animateOnScroll
          animationName="animated fadeInRight"
        >
          <img
            class="col-12 col-md-6 card-img"
            src="./assets/images/app/new_card2.webp"
            alt="Eco2card back"
          />
        </div>
      </div>
      <div
        class="row text-center join-div hide-on-init py-5 my-5"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <img
          src="./assets/images/style/dots.svg"
          alt=""
          class="join-dots r180"
        />
        <a
          (click)="openModal()"
          class="join-link"
          data-toggle="modal"
          data-target="#form-modal"
        >
          Join the waiting list here
        </a>
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
        <span class="mail-txt"
          >Or
          <a href="mailto:contactus@eco2wallet.com" class="mail-link"
            >contactus@eco2wallet.com</a
          ></span
        >
      </div>
      <div class="footer row">
        <div class="col-md-4 col-12 awards">
          <a href="#" title="Find us on EIT">
            <img
              src="../assets/images/logos/eit.png"
              alt="EIT digital"
              class="award-img"
            />
          </a>
          <a href="#" class="">
            <img
              src="../assets/images/logos/EU commission.png"
              alt="European Commision"
              class="award-img"
            />
          </a>
          <a
            href="https://www.seis.co.uk/united-kingdom/london/fintech/eco2wallet?from=badge"
            title="Find us on SEIS.co.uk"
            target="_blank"
            class=""
          >
            <img
              src="https://www.seis.co.uk/images/memberbadge.png"
              border="0"
              alt="Member of SEIS"
              class="award-img"
            />
          </a>
          <a
            href="https://websummit.com/"
            title="We are in WebSummit"
            target="_blank"
            class=""
          >
            <img
              src="../assets/images/logos/websummit.png"
              border="0"
              alt="WebSummit impact startup"
              class="award-img"
            />
          </a>
        </div>
        <div class="col-md-6 col-12 my-5 my-md-0 social-networks">
          <a href="tel:+447865493029" class="social-link">
            <fa-icon [icon]="telephone" class="social-icon"></fa-icon>
          </a>
          <a
            href="https://www.linkedin.com/company/eco2wallet/"
            class="social-link"
          >
            <fa-icon [icon]="linkedin" class="social-icon"></fa-icon>
          </a>
          <a
            href="https://www.facebook.com/Eco2wallet-green-digital-wallet-108859671462372"
            class="social-link"
          >
            <fa-icon [icon]="facebook" class="social-icon"></fa-icon>
          </a>
          <a href="https://www.instagram.com/eco2wallet/" class="social-link">
            <fa-icon [icon]="instagram" class="social-icon"></fa-icon>
          </a>
          <img
            src="./assets/images/style/dots.svg"
            alt=""
            class="network-dots r90 black-and-white"
          />
          <p class="copyright-txt col-12 col-md-auto d-md-inline-block">
            U.K. Company # 11174218, eco2wallet, eco2card, eco2app, and
            associated brands are registered under UK law. 2021.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
