import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public openNav() {
    document.getElementById('sideNav').style.width = '250px';
  }

  public closeNav() {
    document.getElementById('sideNav').style.width = '0';
  }

  openModal(): void {
    $('#form-modal').modal('show');
  }
  closeModal(): void {
    $('#form-modal').modal('hide');
  }
}
