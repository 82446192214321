import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { BusinessComponent } from './pages/business/business.component';
import { PersonalComponent } from './pages/personal/personal.component';

const routes: Routes = [
  { path: '',   redirectTo: '/personal', pathMatch: 'full' },
  { path: 'personal', component: PersonalComponent },
  { path: 'business', component: BusinessComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: '**',   redirectTo: '/personal' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
