<main>
  <div class="trees-background">
    <div class="col-12 text-center">
      <h1
        class="size-m g-color mb-0 hide-on-init"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <img
          src="./assets/images/style/dots.svg"
          alt=""
          class="join-dots r180"
        />
        About Us
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
      </h1>
    </div>
    <div class="row">
      <div
        class="col-12 text-center first-txt-div hide-on-init"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        <p class="first-txt">
          A <span class="go-color">rainforest</span> in your wallet, with
          <span class="gy-color size-l">eco2wallet</span>
        </p>
      </div>
    </div>
    <div class="about-text-div">
      <p class="about-text text-center">
        <span class="g-color">Eco2wallet</span>, was funded in 2020 by Yodaly
        S-R, in the middle of a hard lockdown in London, UK with the main
        objective of creating a simple way for individuals and retail to be
        environmentally conscious while redeem their environmental impact with
        real trees in the Colombian Amazon, because
        <strong class="large-font"
          >helping the environment via a digital wallet</strong
        >
        is our motto, and
        <strong class="large-font"
          >creating an individual market of emissions backed by
          additionality</strong
        >
        is our goal
      </p>
    </div>
    <div class="row values-div py-5 my-5">
      <h1
        class="size-m g-color round-font d-block hide-on-init"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        Values
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
      </h1>
      <div class="values-row mt-5">
        <div class="value-container">
          <h2 class="value-title">Additionality</h2>
          <p class="value-txt">
            Our actions create additional value to the environment
          </p>
        </div>
        <div class="value-container">
          <h2 class="value-title">Bold knowledge</h2>
          <p class="value-txt">Our team is expert, flexible and intuitive</p>
        </div>
        <div class="value-container">
          <h2 class="value-title">We Love Respect</h2>
          <p class="value-txt">
            Our space is neutral, diverse, direct, and respectful
          </p>
        </div>
        <div class="value-container">
          <h2 class="value-title">Inspire innovation</h2>
          <p class="value-txt">
            Our passion is to foster new and creative ideas
          </p>
        </div>
        <div class="value-container">
          <h2 class="value-title">People, Planet, Partnerships</h2>
          <p class="value-txt">Our customer is at the centre of the 3P's</p>
        </div>
      </div>
    </div>
    <div class="row map-div">
      <h1
        class="size-m g-color round-font d-block hide-on-init"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        Global presence
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
      </h1>
      <div class="col-12">
        <img src="./assets/images/app/world.png" class="map-img" alt="Map" />
      </div>
    </div>
    <div class="row values-div py-5 my-5">
      <h1
        class="size-m g-color round-font d-block hide-on-init"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        Careers
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
      </h1>
      <div class="row no-gutters text-center careers-desc">
        <p>Join us if you want to make an impact on the Green Fintech world</p>
      </div>
      <div class="careers-row">
        <div class="career-div">
          <span class="career-position">Intern</span>
          <span class="career-place">London, UK</span>
          <span class="career-description"
            >Eco2wallet is currently hiring an intern in London, if you would
            like to participate in the selection process, send your CV to
            <a href="mailto:">HR@eco2wallet.com</a> with the subject: Intern UK
          </span>
        </div>
        <div class="career-div">
          <span class="career-position">Intern</span>
          <span class="career-place">Bogotá, Colombia</span>
          <span class="career-description"
            >Eco2wallet is currently hiring an intern in Bogotá, if you would
            like to participate in the selection process, send your CV to
            <a href="mailto:">HR@eco2wallet.com</a> with the subject: Intern
            Colombia
          </span>
        </div>
      </div>
    </div>
    <div class="row team-div">
      <div class="col-12 text-center mb-4 pt-5 mt-5">
        <div
          class="col-12 hide-on-init"
          animateOnScroll
          animationName="animated fadeInUp"
        >
          <img
            src="./assets/images/logos/F5FF.jpg"
            class="f5ff-img"
            alt="F5FF logo"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="row no-gutters">
          <div class="col-12 profiles-row mb-5 pb-5">
            <div
              class="profile-avatar hide-on-init animated-delay-05"
              animateOnScroll
              animationName="animated fadeInRight"
            >
              <img
                src="./assets/images/staff/yodaly.png"
                class="profile-img"
                alt="Yodaly Sierra-Rubio"
              />
              <h1 class="profile-name">Yodaly Sierra-Rubio</h1>
              <h2 class="profile-job">CEO-Founder.</h2>
            </div>
            <div
              class="profile-avatar hide-on-init animated-delay-1"
              animateOnScroll
              animationName="animated fadeInRight"
            >
              <img
                src="./assets/images/staff/elena2.webp"
                class="profile-img circle-img"
                alt="Elena Marrioti"
              />
              <h1 class="profile-name">Elena Marrioti</h1>
              <h2 class="profile-job">Chief Business Developer.</h2>
            </div>
            <div
              class="profile-avatar hide-on-init animated-delay-15"
              animateOnScroll
              animationName="animated fadeInRight"
            >
              <img
                src="./assets/images/staff/hannah.jpeg"
                class="profile-img circle-img"
                alt="Tatiana Cortes"
              />
              <h1 class="profile-name">Hanna</h1>
              <h2 class="profile-job">Partnerships Executive.</h2>
            </div>
            <div
              class="profile-avatar hide-on-init animated-delay-2"
              animateOnScroll
              animationName="animated fadeInRight"
            >
              <img
                src="./assets/images/staff/jana.png"
                class="profile-img"
                alt="Jana Duarte"
              />
              <h1 class="profile-name">Jana Duarte</h1>
              <h2 class="profile-job">Chief Financial officer.</h2>
            </div>
            <div
              class="profile-avatar hide-on-init animated-delay-25"
              animateOnScroll
              animationName="animated fadeInRight"
            >
              <img
                src="./assets/images/staff/diana.png"
                class="profile-img"
                alt="Jana Duarte"
              />
              <h1 class="profile-name">Diana Gutierrez</h1>
              <h2 class="profile-job">Technical Manager.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row values-div py-5 my-5">
      <h1
        class="size-m g-color round-font d-block hide-on-init mb-5"
        animateOnScroll
        animationName="animated fadeInUp"
      >
        External advisors
        <img src="./assets/images/style/dots.svg" alt="" class="join-dots" />
      </h1>
      <div class="row">
        <div class="col-12 profiles-row mb-5 pb-5">
          <div
            class="profile-avatar hide-on-init animated-delay-05"
            animateOnScroll
            animationName="animated fadeInRight"
          >
            <img
              src="./assets/images/staff/tatiana.png"
              class="profile-img circle-img"
              alt="Tatiana Cortes"
            />
            <h1 class="profile-name">Tatiana Cortes</h1>
            <h2 class="profile-job">Chief Legal officer.</h2>
          </div>
          <div
            class="profile-avatar hide-on-init animated-delay-05"
            animateOnScroll
            animationName="animated fadeInRight"
          >
            <img
              src="./assets/images/staff/cynthia.jpeg"
              class="profile-img circle-img"
              alt="Cynthia Alvarado"
            />
            <h1 class="profile-name">Cynthia Alvarado</h1>
            <h2 class="profile-job">Research Manager.</h2>
          </div>
          <div
            class="profile-avatar hide-on-init animated-delay-05"
            animateOnScroll
            animationName="animated fadeInRight"
          >
            <img
              src="./assets/images/staff/doriana.jpeg"
              class="profile-img circle-img"
              alt="Doriana Zintro"
            />
            <h1 class="profile-name">Doriana Zintro</h1>
            <h2 class="profile-job">Carbon Intern</h2>
          </div>
          <div
            class="profile-avatar hide-on-init animated-delay-05"
            animateOnScroll
            animationName="animated fadeInRight"
          >
            <img
              src="./assets/images/staff/Peter Atkinston.jpg"
              class="profile-img circle-img"
              alt="Peter Atkinston"
            />
            <h1 class="profile-name">Peter Atkinston</h1>
            <h2 class="profile-job">Executive managing Director. Tech</h2>
            <h3 class="profile-city">Barclays Investment Bank</h3>
          </div>
          <div
            class="profile-avatar hide-on-init animated-delay-1"
            animateOnScroll
            animationName="animated fadeInRight"
          >
            <img
              src="./assets/images/staff/Jose Tovar.jpeg"
              class="profile-img circle-img"
              alt="Jose Tovar"
            />
            <h1 class="profile-name">Jose Tovar</h1>
            <h2 class="profile-job">Country Manager</h2>
            <h3 class="profile-city">Colombia</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="footer row">
      <div class="col-md-4 col-12 awards">
        <a href="#" title="Find us on EIT">
          <img
            src="../assets/images/logos/eit.png"
            alt="EIT digital"
            class="award-img"
          />
        </a>
        <a href="#" class="">
          <img
            src="../assets/images/logos/EU commission.png"
            alt="European Commision"
            class="award-img"
          />
        </a>
        <a
          href="https://www.seis.co.uk/united-kingdom/london/fintech/eco2wallet?from=badge"
          title="Find us on SEIS.co.uk"
          target="_blank"
          class=""
        >
          <img
            src="https://www.seis.co.uk/images/memberbadge.png"
            border="0"
            alt="Member of SEIS"
            class="award-img"
          />
        </a>
        <a
          href="https://websummit.com/"
          title="We are in WebSummit"
          target="_blank"
          class=""
        >
          <img
            src="../assets/images/logos/websummit.png"
            border="0"
            alt="WebSummit impact startup"
            class="award-img"
          />
        </a>
      </div>
      <div class="col-md-6 col-12 my-5 my-md-0 social-networks">
        <a href="tel:+447865493029" class="social-link">
          <fa-icon [icon]="telephone" class="social-icon"></fa-icon>
        </a>
        <a
          href="https://www.linkedin.com/company/eco2wallet/"
          class="social-link"
        >
          <fa-icon [icon]="linkedin" class="social-icon"></fa-icon>
        </a>
        <a
          href="https://www.facebook.com/Eco2wallet-green-digital-wallet-108859671462372"
          class="social-link"
        >
          <fa-icon [icon]="facebook" class="social-icon"></fa-icon>
        </a>
        <a href="https://www.instagram.com/eco2wallet/" class="social-link">
          <fa-icon [icon]="instagram" class="social-icon"></fa-icon>
        </a>
        <img
          src="./assets/images/style/dots.svg"
          alt=""
          class="network-dots r90 black-and-white"
        />
        <p class="copyright-txt col-12 col-md-auto d-md-inline-block">
          U.K. Company # 11174218, eco2wallet, eco2card, eco2app, and associated
          brands are registered under UK law. 2021.
        </p>
      </div>
    </div>
  </div>
</main>
